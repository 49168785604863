import Vue from "vue";
import Toast from 'vue-toastification';

Vue.use(Toast, {
    position: 'top-center',
    timeout: 3000,
});

export default new Vue({
    el: '#notification-wrapper',
    methods: {
        showErrors(errors) {
            errors.forEach(function(error) {
                this.$toast.error(error, {
                    timeout: false,
                });
            }.bind(this));
        },
        showNotifications(notifications) {
            notifications.forEach(function(notification) {
                this.$toast(notification);
            }.bind(this));
        },
        showImportantNotifications(notifications) {
            notifications.forEach(function(notification) {
                this.$toast(notification, {
                    timeout: false,
                });
            }.bind(this));
        },
    },
});
