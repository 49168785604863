import axios from 'axios';
import lodash from 'lodash';
import notifications from './notifications';
import Vue from 'vue';
import * as Sentry from '@sentry/vue';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.head.querySelector('meta[name="csrf-token"]').content;

window._ = lodash;
window.notificationHandler = notifications;
window.Vue = Vue;

Sentry.init({
    Vue,
    dsn: process.env.MIX_SENTRY_DSN,
    environment: process.env.MIX_APP_ENV,
    release: process.env.MIX_SENTRY_RELEASE,
    tracesSampleRate: process.env.MIX_SENTRY_TRACES_SAMPLE_RATE,
});
